/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #004b91;
$success: #42f57b;
// $warning: #f8991d;

$navbar-height: 9vh;
$footer-height: 24vh;

.theme-navbar {
  min-height: $navbar-height;
  background-color: #012974;
  color: #fff;
  font-weight: bold;

  .nav-link {
    color: #fff;
  }

  .nav-link:hover {
    color: #000;
    text-decoration: underline;
    background-color: rgb(243, 103, 36);
    border-radius: 5px;
  }
}

#wrapper {
  min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
  min-height: $footer-height;
  background-color: #012974;
  color: #fff;

  a {
    color: #fff;
  }

  a:hover {
    color: #00a8e3;
  }
}

.brand-logo {
  height: auto;
  width: 190px;
}

.brand-logo-efpem {
  height: auto;
  width: 83px;
}

.navbar {
  padding: 0px !important;
}

#root {
  font-family: Arial, sans-serif !important;
}
.justificado {
  text-align: justify;
  a {
    color: #0c365c;
    font-weight: bold;
  }
}
.bbl {
  color: #000 !important;
}

.my-pagination .page-link {
  background-color: #fff;
  color: #000;
}

.my-pagination .page-link:hover {
  background-color: rgb(247, 93, 22);
  color: #000;
}
.page-link.active,
.active > .page-link {
  color: #000 !important;
  background-color: rgb(243, 103, 36) !important;
  border-color: rgb(223, 95, 36) !important;
}

.nav-container {
  padding-block: 0.5rem;
}

.eslogan{
  height: 140px;
  width:  auto;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss";
